.img-thumbnail, body {
    background-color: rgb(217, 217, 217);
}

.navbar-static-top {
    border-color: #d3e0e9;
    /*background-color: #be6127;*/
}

/*Uloadcare*/
form.upload-form .btn, form.upload-form .description{
	display: none;
}